



















































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';

// This works, even this is not unique
const DEFAULT_REF = 'myAutocomplete';
/**
 * These Components are introduced to change components design easily. Otherwise, you may need to find every use of a
 * component and change the design there
 * For docs see https://vuetifyjs.com/en/api/v-autocomplete/
 */
@Component({
  components: {
    RJChip: () => import(
        '@/components/shared/custom-vuetify/RJChip.vue'),
  },
})
export default class RJAutocomplete extends Vue {

  @VModel({default: ''})
  public selectInput!: any;

  @Prop({default: true})
  public allowOverflow!: boolean;

  @Prop({default: 'mdi-chevron-down'})
  public appendIcon!: string;

  @Prop({default: undefined})
  public appendOuterIcon!: string;

  @Prop({default: true})
  public attach!: any;

  @Prop({default: false})
  public autoSelectFirst!: boolean;

  @Prop({default: false})
  public autofocus!: boolean;

  @Prop({default: 'decent-gray'})
  public backgroundColor!: string;

  @Prop({default: false})
  public cacheItems!: boolean;

  /**
   * Alternative way to display chipContent
   */
  @Prop({default: undefined})
  public chipContent!: any;

  /**
   * Something that can happen on chip clicked
   */
  @Prop({default: undefined})
  public atClick!: any;

  @Prop({default: 'background'})
  private chipColor!: string | ((item: any) => string);

  @Prop({default: 'on-background-variant'})
  private chipTextColor!: string | ((item: any) => string);

  @Prop({default: '$clear'})
  public clearIcon!: string;

  @Prop({default: false})
  public clearable!: boolean;

  @Prop({default: undefined})
  public color!: string;

  @Prop({default: undefined})
  public counter!: boolean | string | number;

  /**
   * It's a function
   */
  @Prop({default: undefined})
  public counterValue!: any;

  @Prop({default: false})
  public disableLookup!: boolean;

  @Prop({default: false})
  public disabled!: boolean;

  @Prop({default: false})
  public eager!: boolean;

  @Prop({default: undefined})
  public errorMessages!: string | string[];

  @Prop({default: undefined})
  public filter!: any;

  @Prop({default: false})
  public fullWidth!: boolean;

  @Prop({default: false})
  public hideSpinButtons!: boolean;

  @Prop({default: false})
  public hideSelected!: boolean;

  /**
   * Can be a string or a function
   */
  @Prop({default: 'text'})
  public itemText!: any;

  /**
   * Can be a string or a function
   */
  @Prop({default: 'value'})
  public itemValue!: any;

  @Prop({default: () => []})
  public items!: any[];

  @Prop({default: undefined})
  public label!: string;

  @Prop({default: 2})
  public loaderHeight!: number | string;

  @Prop({default: false})
  public loading!: boolean | string;

  /**
   * See Props at https://vuetifyjs.com/en/api/v-menu/
   */
  @Prop({default: undefined})
  public menuProps!: any;

  @Prop({default: undefined})
  public multiple!: boolean;

  @Prop({default: '$vuetify.noDataText'})
  public noDataText!: string;

  @Prop({default: false})
  public noFilter!: boolean;

  @Prop({default: false})
  public openOnClear!: boolean;

  @Prop({default: false})
  public persistentPlaceholder!: boolean;

  @Prop({default: undefined})
  public placeholder!: string;

  @Prop({default: undefined})
  public prefix!: string;

  @Prop({default: undefined})
  public prependIcon!: string;

  @Prop({default: undefined})
  public prependInnerIcon!: string;

  @Prop({default: false})
  public readonly!: boolean;

  @Prop({default: DEFAULT_REF})
  public vRef!: string;

  @Prop({default: false})
  public returnObject!: boolean;

  @Prop({default: false})
  public reverse!: boolean;

  @Prop({default: 'text'})
  public type!: string;

  @Prop({default: undefined})
  public value!: any;

  @Prop({default: undefined})
  public vOn!: any;

  private get getMenuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }

  private onBlur(value: any) {
    this.$emit('blur', value);
  }

  private onChange(value: any) {
    this.$emit('change', value);
  }

  private onClick(value: any) {
    this.$emit('click', value);
  }

  // needed to remove onAppendClick, because the input is then not focused on append click. Triggering focus() has not
  // opened the menu

  private onClickAppendOuter(value: any) {
    this.$emit('click:append-outer', value);
  }

  private onClickClear(value: any) {
    this.$emit('click:clear', value);
  }

  private onClickPrepend(value: any) {
    this.$emit('click:prepend', value);
  }

  private onClickPrependInner(value: any) {
    this.$emit('click:prepend-inner', value);
  }

  private onFocus(value: any) {
    this.$emit('focus', value);
  }

  private onInput(value: any) {
    // This emit with value is needed... Why?!?!?
    if (value && value[this.itemValue] === null) {
      value = undefined;
      this.selectInput = value;
    }
    this.$emit('input', value);
  }

  private onRemove(toRemove?: any) {
    if (toRemove) {
      // Find the item in the selectInput array
      let index = this.selectInput.findIndex((item: any) => item === toRemove);
      // If not found compare text and value of the items
      if (index === -1) {
        index = this.selectInput.findIndex((item: any) => this.getItemValue(item) === this.getItemValue(toRemove));
      }
      // If found remove it from the array
      if (index > -1) {
        this.selectInput.splice(index, 1);
      }
    }
    this.onChange(this.selectInput);
    this.$emit('remove', toRemove);
  }


  private getItemText(item: any): string {
    if (typeof item === 'object') {
      if (typeof this.itemText === 'string') {
        return item[this.itemText];
      }
      return this.itemText(item);
    }
    return item;
  }

  private getItemValue(item: any): any {
    if (typeof item === 'object') {
      if (typeof this.itemValue === 'string') {
        return item[this.itemValue];
      }
      return this.itemValue(item);
    }
    return item;
  }

  private get sortedItems() {
    return [...this.items].sort((a, b) => this.getItemText(a).toLowerCase() < this.getItemText(b).toLowerCase() ? -1 : 1);
  }
}
